import { useHasTreatment } from '@motion/web-common/flags'

import { type User } from 'firebase/auth'
import { useCallback } from 'react'

import { useCheckPendingInvite } from '../rpc'

// The point of this modal is to allow users who log-in for the first time
// through a route other than /checkout to create an account rather than getting the
// no account screen. For Oauth users we show it if the account is < 1 minute old
export const useShouldShowLoginEmailNotFound = () => {
  const hasLoginNoEmailFoundModal = useHasTreatment(
    'login-no-email-found-modal'
  )
  const { mutateAsync: checkInvited } = useCheckPendingInvite()

  return useCallback(
    async (user: User) => {
      if (
        hasLoginNoEmailFoundModal &&
        user.metadata.creationTime &&
        // Do not show for password users since we already check this on the login page
        user.providerData[0]?.providerId !== 'password'
      ) {
        const creationTime = new Date(user.metadata.creationTime).getTime()
        const currentTime = Date.now()
        const timeSinceCreated = currentTime - creationTime
        const maxTimeSinceCreated = 60 * 1000

        if (timeSinceCreated < maxTimeSinceCreated) {
          if (user.email) {
            let hasPendingInvite = await checkInvited({ email: user.email })

            if (hasPendingInvite.pendingInvite) {
              // If the user has a pending invite, we should not show the modal
              return false
            }
          }
          return true
        }
      }

      return false
    },
    [hasLoginNoEmailFoundModal, checkInvited]
  )
}
